






































import Vue from "vue";

export default Vue.component("QModal", {
  props: {
    parent: {
      type: undefined, // HTMLElement
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },

    title: {
      type: String,
      required: false,
      default: "",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },

    okMessage: {
      type: String,
      required: false,
      default: "OK",
    },
    ngMessage: {
      type: String,
      required: false,
      default: "NG",
    },
    isShowNg: {
      type: Boolean,
      required: false,
      default: false,
    },
    status: {
      type: String,
      default: "error",
    },
    okClass: {
      type: String,
      default: "mb__add-color-red",
    },
  },
  data() {
    return {
      delay: 200,
      isShow: false,
    };
  },
  mounted() {
    if (this.isOpen) {
      this.isShow = false;
      setTimeout(() => {
        this.isShow = true;
      }, this.delay);
    }

    (this.parent as HTMLElement).appendChild(this.$el);
  },
  methods: {
    handleClickCancel() {
      this.$emit("cancel");
      this.close();
    },
    handleClickOk() {
      this.$emit("ok");
      this.close();
    },
    handleClickNg() {
      this.$emit("ng");
      this.close();
    },

    close() {
      this.remove();
    },
    remove() {
      this.isShow = false;

      setTimeout(() => {
        this.$el.remove();
      }, this.delay);
    },
    destroyed() {
      this.$el.remove();
    },
  },
});
